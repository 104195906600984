export const metaTags = {
  trending: {
    trending: {
      metaTitle:
        "Trending news in Hindi, breaking news and headlines in Hindi - TheLallantop",
      metaDescription:
        "Trending news in hindi on Thelallantop. Find breaking hindi news and articles headlines. Trending hindi news, photos, video & more",
      metaKeyword:
        "trending news,trending in news,trending news of india,trending news india,trending news in india,trending news today,trending news for today,trending in news today,trending news hindi,hindi trending news,trending news in hindi,trending hindi news",
    },
  },
  latest: {
    latest: {
      metaTitle:
        "Trending news in Hindi, breaking news and headlines in Hindi - TheLallantop",
      metaDescription:
        "Trending news in hindi on Thelallantop. Find breaking hindi news and articles headlines. Trending hindi news, photos, video & more",
      metaKeyword:
        "trending news,trending in news,trending news of india,trending news india,trending news in india,trending news today,trending news for today,trending in news today,trending news hindi,hindi trending news,trending news in hindi,trending hindi news",
    },
  },
  news: {
    news: {
      metaTitle: "Best Latest News and Updates - The Lallantop",
      metaDescription:
        "Stay informed with the latest news and updates from around the world on The Lallantop. Get breaking news, trending stories, and insightful analysis on politics, entertainment, technology, and more.",
      metaKeyword:
        "latest news hindi,latest in hindi news,latest news in hindi,latest news hindi today,latest news in hindi today,latest news hindi india,india's latest news in hindi,latest news in hindi today india,news in hindi yesterday,latest news in hindi headlines,latest news hindi news,latest news in hindi news",
    },
    "social-media": {
      metaTitle: "Social Media News and Trends - The Lallantop",
      metaDescription:
        "Explore the latest news and trends in the world of social media on The Lallantop. Discover viral stories, online culture, social media platforms, and their impact on society.",
      metaKeyword:
        "latest news hindi,latest in hindi news,latest news in hindi,latest news hindi today,latest news in hindi today,latest news hindi india,india's latest news in hindi,latest news in hindi today india,news in hindi yesterday,latest news in hindi headlines,latest news hindi news,latest news in hindi news",
    },
    "latest-news": {
      metaTitle: "Breaking News and Headlines - The Lallantop",
      metaDescription:
        "Get the latest breaking news and headlines on The Lallantop. Stay updated on current affairs, politics, business, sports, and more with our comprehensive coverage.",
      metaKeyword:
        "latest news hindi,latest in hindi news,latest news in hindi,latest news hindi today,latest news in hindi today,latest news hindi india,india's latest news in hindi,latest news in hindi today india,news in hindi yesterday,latest news in hindi headlines,latest news hindi news,latest news in hindi news",
    },
    "top-news": {
      metaTitle: "Top News Stories and Highlights - The Lallantop",
      metaDescription:
        "Stay informed with the top news stories and highlights on The Lallantop. From global events to local happenings, access reliable and unbiased news coverage from our trusted sources.",
      metaKeyword:
        "latest news hindi,latest in hindi news,latest news in hindi,latest news hindi today,latest news in hindi today,latest news hindi india,india's latest news in hindi,latest news in hindi today india,news in hindi yesterday,latest news in hindi headlines,latest news hindi news,latest news in hindi news",
    },
  },
  sports: {
    sports: {
      metaTitle:
        "Latest Sports News (स्पोर्ट्स न्यूज़) in Hindi, Sports न्यूज़ Headlines, खेल समाचार, Latest Sports News Today",
      metaDescription:
        "Latest Sports News in Hindi: Find latest Sports News in Hindi (स्पोर्ट्स न्यूज़), खेल समाचार, क्रिकेट समाचार, live scores, news on Football (फुटबॉल), Badminton (बॅडमिंटन), Cricket (क्रिकेट), Hockey (हॉकी) in Hindi at The Lallantop ",
      metaKeyword:
        "sports news in Hindi, latest sports news, latest sports Hindi news, breaking sports news in Hindi, sports news headlines in Hindi, sports news updates, क्रिकेट समाचार, खेल समाचार, स्पोर्ट्स न्यूज़, फुटबॉल समाचार, बैडमिंटन समाचार, हॉकी समाचार, cricket news today, ",
    },
    news: {
      metaTitle: "Breaking Sports News and Headlines - The Lallantop",
      metaDescription:
        "Get the scoop on the latest sports news at The Lallantop. From game-changing moments to off-field drama, dive into breaking stories, exclusive interviews, and real-time updates on your favorite teams and athletes ",
      metaKeyword:
        "sports news in hindi, cricket news in hindi, world cup 2023, world cup 2023 news, results world cup 2023, icc world cup news, icc world cup",
    },
    profile: {
      metaTitle: "Sports Personalities and Profiles News - The Lallantop",
      metaDescription:
        "Explore profiles and biographies of renowned sports personalities on The Lallantop. Learn about their journeys, achievements, and inspiring stories that make them legends in their respective fields.",
      metaKeyword:
        "Mohammad Rizwan,Kusal Mendis,Devon Conway,Rachin Ravindra,Joe Root        England,Dawid Malan,Virat Kohli,Rohit Sharma,Sadeera Samarawickrama,Abdullah Shafique, Babar Azam,Rashid Khan,Adam Zampa, Rohit Sharma, Hardik Pandya, Shubman Gill, Virat Kohli, Shreyas Iyer, KL Rahul, Ravindra Jadeja, Shardul Thakur, Jasprit Bumrah, Mohammed Siraj, Kuldeep Yadav, Mohammed Shami, Ravichandran Ashwin, Ishan Kishan, Suryakumar Yadav",
    },
    olympics: {
      metaTitle:
        "Paris Olympics 2024 (पेरिस ओलंपिक 2024): Complete India Schedule, Results, and Medal Updates - The Lallantop",
      metaDescription:
        "Stay updated with all the latest news on the 2024 Paris Olympics (2024 पेरिस ग्रीष्मकालीन ओलंपिक) including dates, schedules, match results, and medal tally. Follow India’s performance at the Paris Olympics 2024 (पेरिस ओलंपिक) with detailed coverage in Hindi at The Lallantop.",
      metaKeyword:
        "paris olympics 2024, paris 2024 olympics, olympics in paris 2024, india at paris olympics 2024, 2024 paris olympics schedule, paris olympics results 2024, paris olympics medal tally 2024, पेरिस ओलंपिक 2024, पेरिस ग्रीष्मकालीन ओलंपिक 2024",
    },
    kisse: {
      metaTitle: "Sports Stories and Kisse News - The Lallantop",
      metaDescription:
        "Immerse yourself in the fascinating stories behind the sports on The Lallantop's 'Sports Kisse'.Stay informed with our comprehensive coverage on top sports news around the globe.",
      metaKeyword:
        "icc world cup, world cup, world cup 2023, England vs New Zealand, India vs Pakistan, Pakistan vs Netherlands,Afganistan vs Bangladesh",
    },
    specials: {
      metaTitle: "Sports Specials News - The Lallantop",
      metaDescription:
        "Delve into the world of sports news specials and exclusive top sports news on The Lallantop. From in-depth analysis to behind-the-scenes insights, discover unique perspectives on your favorite sports.",
      metaKeyword:
        "top matches in world cup 2023, world cup matches schedule, top matches in world cup 2023, icc world cup",
    },
  },
  entertainment: {
    entertainment: {
      metaTitle: "Entertainment News In Hindi, Bollywood News | TheLallantop",
      metaDescription:
        "Get the latest entertainment news in Hindi, including Bollywood news, celebrity gossip, and more on TheLallantop. Stay updated with the latest happenings in the world of entertainment.",
      metaKeyword:
        "entertainment news,entertainment news of india,entertainment news india,entertainment news latest,entertainment news bollywood,entertainment news in hindi,entertainment hindi news,hindi entertainment news,entertainment news in hindi,entertainment news hindi,entertainment bollywood news,today's entertainment news,entertainment news today,entertainment news for today,entertainment news of today,entertainment news today headlines,entertainment news headlines today,entertainment news video,entertainment news and gossip,entertainment news of the day,entertainment news headlines,today entertainment news in hindi,entertainment news in hindi today,entertainment news bollywood hindi,entertainment news in hindi tv,entertainment news celebrity,entertainment news bollywood hindi,entertainment news india in hindi,entertainment news in hindi television",
    },
    news: {
      metaTitle: "Latest Entertainment News In Hindi | TheLallantop",
      metaDescription:
        "Stay up-to-date with the latest entertainment news in Hindi. Get breaking news, updates, and gossip from the world of Bollywood and entertainment industry on TheLallantop.",
      metaKeyword:
        "entertainment news,entertainment news of india,entertainment news india,entertainment news latest,entertainment news bollywood,entertainment news in hindi,entertainment hindi news,hindi entertainment news,entertainment news in hindi,entertainment news hindi,entertainment bollywood news,today's entertainment news,entertainment news today,entertainment news for today,entertainment news of today,entertainment news today headlines,entertainment news headlines today,entertainment news video,entertainment news and gossip,entertainment news of the day,entertainment news headlines,today entertainment news in hindi,entertainment news in hindi today,entertainment news bollywood hindi,entertainment news in hindi tv,entertainment news celebrity,entertainment news bollywood hindi,entertainment news india in hindi,entertainment news in hindi television",
    },
    "movies-web-series-review": {
      metaTitle: "Movie and Web Series Reviews In Hindi | TheLallantop",
      metaDescription:
        "Read comprehensive reviews of movies and web series in Hindi. Get insights, ratings, and recommendations for the latest releases on TheLallantop.",
      metaKeyword:
        "entertainment news,entertainment news of india,entertainment news india,entertainment news latest,entertainment news bollywood,entertainment news in hindi,entertainment hindi news,hindi entertainment news,entertainment news in hindi,entertainment news hindi,entertainment bollywood news,today's entertainment news,entertainment news today,entertainment news for today,entertainment news of today,entertainment news today headlines,entertainment news headlines today,entertainment news video,entertainment news and gossip,entertainment news of the day,entertainment news headlines,today entertainment news in hindi,entertainment news in hindi today,entertainment news bollywood hindi,entertainment news in hindi tv,entertainment news celebrity,entertainment news bollywood hindi,entertainment news india in hindi,entertainment news in hindi television",
    },
    "cinema-ke-kisse": {
      metaTitle:
        "Interesting Stories of Cinema | Cinema Ke Kisse | TheLallantop",
      metaDescription:
        "Explore fascinating stories and facts from the world of cinema with 'Cinema Ke Kisse' on TheLallantop. Discover behind-the-scenes anecdotes, trivia, and insights about your favorite movies and actors.",
      metaKeyword:
        "entertainment news,entertainment news of india,entertainment news india,entertainment news latest,entertainment news bollywood,entertainment news in hindi,entertainment hindi news,hindi entertainment news,entertainment news in hindi,entertainment news hindi,entertainment bollywood news,today's entertainment news,entertainment news today,entertainment news for today,entertainment news of today,entertainment news today headlines,entertainment news headlines today,entertainment news video,entertainment news and gossip,entertainment news of the day,entertainment news headlines,today entertainment news in hindi,entertainment news in hindi today,entertainment news bollywood hindi,entertainment news in hindi tv,entertainment news celebrity,entertainment news bollywood hindi,entertainment news india in hindi,entertainment news in hindi television",
    },
    specials: {
      metaTitle: "Entertainment Specials | TheLallantop",
      metaDescription:
        "Dive into special features and exclusive content from the world of entertainment. Find in-depth interviews, profiles, and engaging articles on TheLallantop.",
      metaKeyword:
        "entertainment news,entertainment news of india,entertainment news india,entertainment news latest,entertainment news bollywood,entertainment news in hindi,entertainment hindi news,hindi entertainment news,entertainment news in hindi,entertainment news hindi,entertainment bollywood news,today's entertainment news,entertainment news today,entertainment news for today,entertainment news of today,entertainment news today headlines,entertainment news headlines today,entertainment news video,entertainment news and gossip,entertainment news of the day,entertainment news headlines,today entertainment news in hindi,entertainment news in hindi today,entertainment news bollywood hindi,entertainment news in hindi tv,entertainment news celebrity,entertainment news bollywood hindi,entertainment news india in hindi,entertainment news in hindi television",
    },
  },
  oddnaari: {
    oddnaari: {
      metaTitle: "Oddnaari - Stories of Empowered Women - The Lallantop",
      metaDescription:
        "Explore Oddnaari on The Lallantop and discover inspiring stories of empowered women breaking barriers, challenging norms, and making a difference in the world.",
      metaKeyword: "oddnaari,TheLallantop",
    },
    crime: {
      metaTitle: "Oddnaari Crime - Uncovering Real Stories - The Lallantop",
      metaDescription:
        "Dive into Oddnaari Crime on The Lallantop and uncover real stories of resilience, strength, and courage as women navigate through challenging situations and fight against injustice.",
      metaKeyword: "oddnaari,TheLallantop",
    },
    knowledge: {
      metaTitle:
        "Oddnaari Knowledge - Empowering Women with Information - The Lallantop",
      metaDescription:
        "Expand your knowledge with Oddnaari Knowledge on The Lallantop. Find empowering articles, interviews, and informative content designed to provide women with valuable information and insights",
      metaKeyword: "oddnaari,TheLallantop",
    },
    lifestyle: {
      metaTitle:
        "Oddnaari Lifestyle - Celebrating Women's Choices - The Lallantop",
      metaDescription:
        "Celebrate women's choices and diverse lifestyles with Oddnaari Lifestyle on The Lallantop. Discover articles on fashion, beauty, wellness, relationships, and more, reflecting the vibrant lives women lead.",
      metaKeyword: "oddnaari,TheLallantop",
    },
  },
  technology: {
    technology: {
      metaTitle:
        "Technology News in Hindi - Latest Tech Updates | TheLallantop",
      metaDescription:
        "Stay updated with the latest technology news and updates in Hindi on TheLallantop. Get insights into the world of technology, gadgets, and innovations with our comprehensive coverage.",
      metaKeyword:
        "tech news,news on tech,news of tech,technology news,tech news top,news on technology,hindi tech news,tech news hindi,tech news in hindi,tech hindi news,technology in hindi,tech news for today,tech news latest,tech news today,latest news on tech,tech news of today,latest tech news,tech latest news,latest in tech news,news on latest technology,latest news of technology,technology latest news,technology news latest,tech news india,today news on technology,news on technology today,technology news today,news technology today,technology news of today,technology news for today,news gadgets,technology news hindi,technology hindi news,gadget news hindi,gadget news in hindi,news tech hindi,technology news in hindi,hindi technology news,hindi gadgets news,technology news india,tech news sites,technology news articles,latest tech news in hindi,technology news google,tech news website,latest tech news in india,tech new gadgets,tech news new,tech news mobile,news on new technology,gadgets latest news,tech news latest india,latest gadgets news,latest gadget news,latest news about gadgets,latest tech news india,latest news gadgets,latest tech updates,technology news recent,latest tech news today,tech updates today,latest technology news hindi,hindi technical news,tech news updates,tech news recent,latest news on technology in india,tech news hindi today,latest gadgets news hindi,technology news update,trending tech news in hindi",
    },
    "tech-news": {
      metaTitle: "Latest Tech News in Hindi - Top Headlines | TheLallantop",
      metaDescription:
        "Get the latest and most important tech news headlines in Hindi on TheLallantop. Stay informed about the latest developments in the world of technology with our comprehensive coverage.",
      metaKeyword:
        "tech news,news on tech,news of tech,technology news,tech news top,news on technology,hindi tech news,tech news hindi,tech news in hindi,tech hindi news,technology in hindi,tech news for today,tech news latest,tech news today,latest news on tech,tech news of today,latest tech news,tech latest news,latest in tech news,news on latest technology,latest news of technology,technology latest news,technology news latest,tech news india,today news on technology,news on technology today,technology news today,news technology today,technology news of today,technology news for today,news gadgets,technology news hindi,technology hindi news,gadget news hindi,gadget news in hindi,news tech hindi,technology news in hindi,hindi technology news,hindi gadgets news,technology news india,tech news sites,technology news articles,latest tech news in hindi,technology news google,tech news website,latest tech news in india,tech new gadgets,tech news new,tech news mobile,news on new technology,gadgets latest news,tech news latest india,latest gadgets news,latest gadget news,latest news about gadgets,latest tech news india,latest news gadgets,latest tech updates,technology news recent,latest tech news today,tech updates today,latest technology news hindi,hindi technical news,tech news updates,tech news recent,latest news on technology in india,tech news hindi today,latest gadgets news hindi,technology news update,trending tech news in hindi",
    },
    review: {
      metaTitle: "Technology Reviews in Hindi - Expert Opinions | TheLallantop",
      metaDescription:
        "Read expert opinions and reviews on the latest technology products and services in Hindi on TheLallantop. Get insights before making your purchase decisions.",
      metaKeyword:
        "tech news,news on tech,news of tech,technology news,tech news top,news on technology,hindi tech news,tech news hindi,tech news in hindi,tech hindi news,technology in hindi,tech news for today,tech news latest,tech news today,latest news on tech,tech news of today,latest tech news,tech latest news,latest in tech news,news on latest technology,latest news of technology,technology latest news,technology news latest,tech news india,today news on technology,news on technology today,technology news today,news technology today,technology news of today,technology news for today,news gadgets,technology news hindi,technology hindi news,gadget news hindi,gadget news in hindi,news tech hindi,technology news in hindi,hindi technology news,hindi gadgets news,technology news india,tech news sites,technology news articles,latest tech news in hindi,technology news google,tech news website,latest tech news in india,tech new gadgets,tech news new,tech news mobile,news on new technology,gadgets latest news,tech news latest india,latest gadgets news,latest gadget news,latest news about gadgets,latest tech news india,latest news gadgets,latest tech updates,technology news recent,latest tech news today,tech updates today,latest technology news hindi,hindi technical news,tech news updates,tech news recent,latest news on technology in india,tech news hindi today,latest gadgets news hindi,technology news update,trending tech news in hindi",
    },
    sciencekaari: {
      metaTitle: "Science Kaari - Science News & Updates | TheLallantop",
      metaDescription:
        "Dive into the latest scientific discoveries, research, and news with our Science Kaari section on TheLallantop. Explore the wonders of science.",
      metaKeyword:
        "tech news,news on tech,news of tech,technology news,tech news top,news on technology,hindi tech news,tech news hindi,tech news in hindi,tech hindi news,technology in hindi,tech news for today,tech news latest,tech news today,latest news on tech,tech news of today,latest tech news,tech latest news,latest in tech news,news on latest technology,latest news of technology,technology latest news,technology news latest,tech news india,today news on technology,news on technology today,technology news today,news technology today,technology news of today,technology news for today,news gadgets,technology news hindi,technology hindi news,gadget news hindi,gadget news in hindi,news tech hindi,technology news in hindi,hindi technology news,hindi gadgets news,technology news india,tech news sites,technology news articles,latest tech news in hindi,technology news google,tech news website,latest tech news in india,tech new gadgets,tech news new,tech news mobile,news on new technology,gadgets latest news,tech news latest india,latest gadgets news,latest gadget news,latest news about gadgets,latest tech news india,latest news gadgets,latest tech updates,technology news recent,latest tech news today,tech updates today,latest technology news hindi,hindi technical news,tech news updates,tech news recent,latest news on technology in india,tech news hindi today,latest gadgets news hindi,technology news update,trending tech news in hindi",
    },
    specials: {
      metaTitle: "Technology Specials - Insights and Analysis | TheLallantop",
      metaDescription:
        "Explore in-depth insights and analysis on various technology topics with our Technology Specials section on TheLallantop. Stay informed with our comprehensive coverage.",
      metaKeyword:
        "tech news,news on tech,news of tech,technology news,tech news top,news on technology,hindi tech news,tech news hindi,tech news in hindi,tech hindi news,technology in hindi,tech news for today,tech news latest,tech news today,latest news on tech,tech news of today,latest tech news,tech latest news,latest in tech news,news on latest technology,latest news of technology,technology latest news,technology news latest,tech news india,today news on technology,news on technology today,technology news today,news technology today,technology news of today,technology news for today,news gadgets,technology news hindi,technology hindi news,gadget news hindi,gadget news in hindi,news tech hindi,technology news in hindi,hindi technology news,hindi gadgets news,technology news india,tech news sites,technology news articles,latest tech news in hindi,technology news google,tech news website,latest tech news in india,tech new gadgets,tech news new,tech news mobile,news on new technology,gadgets latest news,tech news latest india,latest gadgets news,latest gadget news,latest news about gadgets,latest tech news india,latest news gadgets,latest tech updates,technology news recent,latest tech news today,tech updates today,latest technology news hindi,hindi technical news,tech news updates,tech news recent,latest news on technology in india,tech news hindi today,latest gadgets news hindi,technology news update,trending tech news in hindi",
    },
  },
  business: {
    business: {
      metaTitle:
        "Business News in Hindi - Latest Business Updates | TheLallantop",
      metaDescription:
        "Stay updated with the latest business news and updates in Hindi on TheLallantop. Get insights into the world of business and finance with our comprehensive coverage.",
      metaKeyword:
        "business news,news of business today,business news today,today&#x27;s news business,hindi business news,business news of today,news hindi business,business news in hindi,hindi news business,business news hindi,business news for today,news business in hindi,finance news,business news india,indian business news,news about business in india,news business india,latest news business,business news latest,latest business news,latest news of business,business news hindi today,hindi business news today,business news of today in hindi,business news today hindi,top business news today,latest business news india,latest news business india,indian business news today,business news india latest,business news of india today,top business news today india,business news recent,business news daily,business news current,business news in hindi latest,current business news,latest business news hindi,latest hindi business news,latest business news in hindi,latest news business line,business news live india,indian business news in hindi,business news in hindi india,latest business news today,today&#x27;s latest business news,business news india hindi,business news india in hindi,business news articles,business news update,business samachar,business breaking news in hindi,recent business news india,current business news india",
    },
    "business-news": {
      metaTitle: "Latest Business News in Hindi - Top Headlines | TheLallantop",
      metaDescription:
        "Get the latest and most important business news headlines in Hindi on TheLallantop. Stay informed about the latest developments in the business world with our comprehensive coverage.",
      metaKeyword:
        "business news,news of business today,business news today,today&#x27;s news business,hindi business news,business news of today,news hindi business,business news in hindi,hindi news business,business news hindi,business news for today,news business in hindi,finance news,business news india,indian business news,news about business in india,news business india,latest news business,business news latest,latest business news,latest news of business,business news hindi today,hindi business news today,business news of today in hindi,business news today hindi,top business news today,latest business news india,latest news business india,indian business news today,business news india latest,business news of india today,top business news today india,business news recent,business news daily,business news current,business news in hindi latest,current business news,latest business news hindi,latest hindi business news,latest business news in hindi,latest news business line,business news live india,indian business news in hindi,business news in hindi india,latest business news today,today&#x27;s latest business news,business news india hindi,business news india in hindi,business news articles,business news update,business samachar,business breaking news in hindi,recent business news india,current business news india",
    },
    specials: {
      metaTitle: "Business Specials - Insights and Analysis | TheLallantop",
      metaDescription:
        "Explore in-depth insights and analysis on various business topics with our Business Specials section on TheLallantop. Stay informed with our comprehensive coverage.",
      metaKeyword:
        "business news,news of business today,business news today,today&#x27;s news business,hindi business news,business news of today,news hindi business,business news in hindi,hindi news business,business news hindi,business news for today,news business in hindi,finance news,business news india,indian business news,news about business in india,news business india,latest news business,business news latest,latest business news,latest news of business,business news hindi today,hindi business news today,business news of today in hindi,business news today hindi,top business news today,latest business news india,latest news business india,indian business news today,business news india latest,business news of india today,top business news today india,business news recent,business news daily,business news current,business news in hindi latest,current business news,latest business news hindi,latest hindi business news,latest business news in hindi,latest news business line,business news live india,indian business news in hindi,business news in hindi india,latest business news today,today&#x27;s latest business news,business news india hindi,business news india in hindi,business news articles,business news update,business samachar,business breaking news in hindi,recent business news india,current business news india",
    },
  },
  factcheck: {
    factcheck: {
      metaTitle: "Fact Check - Verify the Truth | TheLallantop",
      metaDescription:
        "Get accurate and reliable fact checks on a wide range of topics with our Fact Check section on TheLallantop. Stay informed and verify the truth.",
      metaKeyword: "FactCheck,TheLallantop",
    },
    latest: {
      metaTitle: "Latest Fact Checks - Stay Informed | TheLallantop",
      metaDescription:
        "Stay informed with the latest fact checks on a wide range of topics with our Fact Check section on TheLallantop. Get accurate information and verify the truth.",
      metaKeyword: "FactCheck,TheLallantop",
    },
    aboutUs: {
      metaTitle: "About Us - The Lallantop Fact Check: Truth in News",
      metaDescription:
        "Learn about The Lallantop Fact Check, our commitment to truth in news, and how we verify information to deliver accurate and reliable content",
    },
    contactUs: {
      metaTitle: "Contact Us - The Lallantop Fact Check: Reach Out to Us",
      metaDescription:
        "Contact The Lallantop Fact Check to share feedback, report misinformation, or get in touch. We value your input and aim for transparency.",
    },
    correctionPolicy: {
      metaTitle:
        "Correction Policy - The Lallantop Fact Check: Ensuring Accuracy",
      metaDescription:
        "Discover The Lallantop Fact Check's correction policy, our commitment to accuracy, and how we handle and rectify errors in our reporting",
    },
    methodology: {
      metaTitle:
        "Methodology - The Lallantop Fact Check: Our Verification Process",
      metaDescription:
        "Explore The Lallantop Fact Check's methodology and how we verify information to provide you with credible and trustworthy news",
    },
  },
  lallankhas: {
    lallankhas: {
      metaTitle: "Lallankhas | Hindi Satire and Humor | TheLallantop",
      metaDescription:
        "Explore the lighter side of news and current affairs with Lallankhas, a section dedicated to Hindi satire and humor. Find witty commentary and satirical takes on trending topics on TheLallantop.",
      metaKeyword: "Lallankhas,TheLallantop",
    },
    araamkursi: {
      metaTitle: "Araamkursi | Political Satire | TheLallantop",
      metaDescription:
        "Enjoy political satire and humorous takes on the current political scenario with Araamkursi on TheLallantop. Discover witty commentaries, cartoons, and funny insights.",
      metaKeyword: "Lallankhas,TheLallantop",
    },
    others: {
      metaTitle: "Lallankhas - Other Content | TheLallantop",
      metaDescription:
        "Explore a variety of other entertaining and engaging content created by Lallankhas on TheLallantop. Discover unique perspectives, creative pieces, and entertaining articles.",
      metaKeyword: "Lallankhas,TheLallantop",
    },
    specials: {
      metaTitle: "Lallankhas Specials | TheLallantop",
      metaDescription:
        "Dive into special features and exclusive content from Lallankhas. Find intriguing articles, interviews, and creative pieces on TheLallantop.",
      metaKeyword: "Lallankhas,TheLallantop",
    },
    "aasan-bhasa-me": {
      metaTitle: "Lallankhas - Aasan Bhasa Me | TheLallantop",
      metaDescription:
        "Explore unique content in simplified Hindi with 'Aasan Bhasa Me' from Lallankhas. Enjoy easy-to-understand articles, satire, and humor on TheLallantop.",
      metaKeyword: "Lallankhas,TheLallantop",
    },
    "new-monk": {
      metaTitle: "Lallankhas - New Monk | TheLallantop",
      metaDescription:
        "Explore the inspiring journey of a new monk in the Lallankhas series on The Lallantop. Witness their spiritual transformation and discover the path to enlightenment.",
      metaKeyword: "Lallankhas,TheLallantop",
    },
    "political-kisse": {
      metaTitle: "Lallankhas - Political Kisse | TheLallantop",
      metaDescription:
        "Dive into the world of  political tales in the Lallankhas series on The Lallantop. Uncover behind-the-scenes stories, controversies, and insights into the political landscape.",
      metaKeyword: "Lallankhas,TheLallantop",
    },
    profile: {
      metaTitle:
        "Lallankhas - Profiles of Remarkable Personalities | TheLallantop",
      metaDescription:
        "Get to know remarkable personalities through the Lallankhas series on The Lallantop. Explore in-depth profiles, biographies, and insights into individuals making a difference.",
      metaKeyword: "Lallankhas,TheLallantop",
    },
    literature: {
      metaTitle: "Lallankhas - Exploring Literature | TheLallantop",
      metaDescription:
        "Immerse yourself in the world of literature through the Lallankhas series on The Lallantop. Discover reviews, recommendations, and insights into books, authors, and the power of storytelling.",
      metaKeyword: "Lallankhas,TheLallantop",
    },
    "ground-report": {
      metaTitle:
        "Lallankhas- Ground Reports | Real Stories from the Field | TheLallantop  ",
      metaDescription:
        "Uncover real stories from the field through Lallankhas Ground Reports on The Lallantop. Experience on-the-ground journalism, investigative reports, and in-depth analysis of current events.",
      metaKeyword: "Lallankhas,TheLallantop",
    },
    tarikh: {
      metaTitle:
        "Lallankhas Tarikh - Exploring Historical Narratives | The Lallantop",
      metaDescription:
        "Journey through historical narratives with Lallankhas Tarikh on The Lallantop. Discover top stories, significant events, and untold accounts that shape our understanding of the past.",
      metaKeyword: "Lallankhas,TheLallantop",
    },
  },
  education: {
    education: {
      metaTitle:
        "India Education News - Latest Headlines in Hindi | TheLallantop",
      metaDescription:
        "Find the latest education news in India in Hindi on TheLallantop. Stay updated with the current affairs, exam updates, and educational news with TheLallantop.",
      metaKeyword:
        "news about education,educational news,education news,news on education,news for education,educational news for today,education news today,news on education today,education news of today,educational news in india,india education news,education news india,education news latest,latest education news,latest news education,education news in india,india news education,latest news for education,education news of india,latest on education news,news on education in india,latest news about education,education latest news,education news hindi,education news in hindi,educational news in hindi,latest education news in india,education hindi news,hindi education news,education news today in hindi,education news update,education news latest india,latest education news india,latest education news in hindi,education news in hindi today",
    },
    latest: {
      metaTitle: "Latest Education News in India - Exam Updates | TheLallantop",
      metaDescription:
        "Stay updated with the latest education news in India on TheLallantop. Our news on current affairs, exam updates, and educational news will keep you ahead.",
      metaKeyword:
        "news about education,educational news,education news,news on education,news for education,educational news for today,education news today,news on education today,education news of today,educational news in india,india education news,education news india,education news latest,latest education news,latest news education,education news in india,india news education,latest news for education,education news of india,latest on education news,news on education in india,latest news about education,education latest news,education news hindi,education news in hindi,educational news in hindi,latest education news in india,education hindi news,hindi education news,education news today in hindi,education news update,education news latest india,latest education news india,latest education news in hindi,education news in hindi today",
    },
    jobs: {
      metaTitle:
        "Education Jobs in India - Latest Updates on top Jobs | TheLallantop",
      metaDescription:
        "Looking for the best jobs in education? TheLallantop provides the latest updates on education jobs in India. Don't miss out!",
      metaKeyword:
        "news about education,educational news,education news,news on education,news for education,educational news for today,education news today,news on education today,education news of today,educational news in india,india education news,education news india,education news latest,latest education news,latest news education,education news in india,india news education,latest news for education,education news of india,latest on education news,news on education in india,latest news about education,education latest news,education news hindi,education news in hindi,educational news in hindi,latest education news in india,education hindi news,hindi education news,education news today in hindi,education news update,education news latest india,latest education news india,latest education news in hindi,education news in hindi today",
    },
    students: {
      metaTitle:
        "Education News for Students in India - Latest Updates and Insights | TheLallantop",
      metaDescription:
        "TheLallantop focuses on education news in India relevant to students. Get the latest updates on academic policies, exam results, and other educational news on TheLallantop.",
      metaKeyword:
        "news about education,educational news,education news,news on education,news for education,educational news for today,education news today,news on education today,education news of today,educational news in india,india education news,education news india,education news latest,latest education news,latest news education,education news in india,india news education,latest news for education,education news of india,latest on education news,news on education in india,latest news about education,education latest news,education news hindi,education news in hindi,educational news in hindi,latest education news in india,education hindi news,hindi education news,education news today in hindi,education news update,education news latest india,latest education news india,latest education news in hindi,education news in hindi today",
    },
    specials: {
      metaTitle:
        "Education Specials - News and Updates on India's Education System | TheLallantop",
      metaDescription:
        "TheLallantop offers you the latest education news in India through its education specials. Keep yourself updated with news and insights on India's education system with TheLallantop.",
      metaKeyword:
        "news about education,educational news,education news,news on education,news for education,educational news for today,education news today,news on education today,education news of today,educational news in india,india education news,education news india,education news latest,latest education news,latest news education,education news in india,india news education,latest news for education,education news of india,latest on education news,news on education in india,latest news about education,education latest news,education news hindi,education news in hindi,educational news in hindi,latest education news in india,education hindi news,hindi education news,education news today in hindi,education news update,education news latest india,latest education news india,latest education news in hindi,education news in hindi today",
    },
  },
  election: {
    election: {
      metaTitle:
        "Elections 2024: Read Elections 2024 News, Updates | चुनाव 2024 समाचार और लोकसभा चुनाव ",
      metaDescription:
        "Stay informed with the latest election news 2024, analysis, and updates from The Lallantop. Stay connected with The Lallantop for Election Live Coverage",
      metaKeyword:
        "Electionselection results 2024,Election 2024,Election Results Updates,Election News,Election Results News,Election Results,Election,Election Updates",
    },
    "lok-sabha-chunav-2024": {
      metaTitle:
        "Lok Sabha Elections (लोकसभा चुनाव ) 2024: General Elections News, Videos, Results, Election Updates in Hindi",
      metaDescription:
        "Lok Sabha Elections 2024: Stay updated with the latest General Elections news, results, videos, and election (चुनाव  2024) updates in Hindi on www.thelallantop.com",
      metaKeyword:
        "Lok Sabha Elections 2024, Lok Sabha Elections 2024 News, Lok Sabha Elections 2024 Updates, Lok Sabha Elections 2024 Videos, Lok Sabha Chunav 2024, General Elections, Lok Sabha Elections Results, लोकसभा चुनाव 2024, लोकसभा चुनाव परिणाम, लोकसभा चुनाव वीडियो, लोकसभा चुनाव 2024 समाचार",
    },
    "elections-2023": {
      metaTitle: "2023 Election News - Assembly Elections | TheLallantop",
      metaDescription:
        "Relive the excitement and insights of the 2023 Assembly Elections with TheLallantop. Get detailed information and analysis of the election results, trends, and key highlights.",
      metaKeyword:
        "Elections 2023, Madhya Pradesh Assembly Elections 2023, Rajasthan Assembly Elections 2023, Chhattisgarh Assembly Elections 2023, Telangana Assembly Elections 2023, Mizoram Assembly Elections 2023",
    },
    "elections-2022": {
      metaTitle: "2022 Election News - Assembly Elections | TheLallantop",
      metaDescription:
        "Relive the excitement and insights of the 2022 Assembly Elections with TheLallantop. Get detailed information and analysis of the election results, trends, and key highlights.",
      metaKeyword: "Elections, All Elections, Election results",
    },
    "elections-2021": {
      metaTitle: "2021 Election News - Assembly Elections | TheLallantop",
      metaDescription:
        "Relive the excitement and insights of the 2021 Assembly Elections with TheLallantop. Get detailed information and analysis of the election results, trends, and key highlights.",
      metaKeyword: "Elections, All Elections, Election results",
    },

    "elections-2020": {
      metaTitle: "2020 Election News - Assembly Elections | TheLallantop",
      metaDescription:
        "Relive the excitement and insights of the 2020 Assembly Elections with TheLallantop. Get detailed information and analysis of the election results, trends, and key highlights.",
      metaKeyword: "Elections, All Elections, Election results",
    },

    "elections-2019": {
      metaTitle: "2019 Election News - Assembly Elections | TheLallantop",
      metaDescription:
        "Relive the excitement and insights of the 2019 Assembly Elections with TheLallantop. Get detailed information and analysis of the election results, trends, and key highlights.",
      metaKeyword: "Elections, All Elections, Election results",
    },

    "elections-2018": {
      metaTitle: "2018 Election News - Assembly Elections | TheLallantop",
      metaDescription:
        "Relive the excitement and insights of the 2018 Assembly Elections with TheLallantop. Get detailed information and analysis of the election results, trends, and key highlights.",
      metaKeyword: "Elections, All Elections, Election results",
    },

    "elections-2017": {
      metaTitle: "2017 Election News - Assembly Elections | TheLallantop",
      metaDescription:
        "Relive the excitement and insights of the 2017 Assembly Elections with TheLallantop. Get detailed information and analysis of the election results, trends, and key highlights.",
      metaKeyword: "Elections, All Elections, Election results",
    },
    "elections-2016": {
      metaTitle: "2016 Election News - Assembly Elections | TheLallantop",
      metaDescription:
        "Relive the excitement and insights of the 2016 Assembly Elections with TheLallantop. Get detailed information and analysis of the election results, trends, and key highlights.",
      metaKeyword: "Elections, All Elections, Election results",
    },
  },
  text: {
    "lok-sabha-chunav-2024": {
      metaTitle:
        " Lok Sabha Elections 2024: Lok Sabha Chunav News, Constituency, Candidates, Exit Poll, Elections Updates.",
      metaDescription:
        "Lok Sabha Chunav 2024: Get the latest lok sabha elections news, constituency details, candidate information, exit poll, and chunav updates on www.thelallantop.com",
      metaKeyword:
        "Lok Sabha Elections 2024, Lok Sabha Elections 2024 News, Lok Sabha Elections 2024 Updates, Lok Sabha Elections 2024 Videos, Lok Sabha Chunav 2024, General Elections, Lok Sabha Elections Results, लोकसभा चुनाव 2024, लोकसभा चुनाव परिणाम, लोकसभा चुनाव वीडियो, लोकसभा चुनाव 2024 समाचार",
    },
    latest: {
      metaDescription:
        "Latest news in hindi on The lallantop. Find breaking hindi news and articles headlines.      ",
    },
    trending: {
      metaDescription:
        "Latest news in hindi on The lallantop. Find breaking hindi news and articles headlines.      ",
    },
    business: {
      metaTitle: "Business News in Hindi - Text Based Latest Business Updates",
      metaDescription:
        "Stay updated with the latest business news and updates in text format on TheLallantop. Get insights into the world of business and finance with our comprehensive coverage..",
    },
    education: {
      metaTitle: "Education Articles: Learning and Knowledge Resources",
      metaDescription:
        "Find the latest education news in India in Hindi on TheLallantop. Stay updated with the current affairs, exam updates, and educational news in text format.",
    },
    election: {
      metaTitle:
        "Election News and Insights: Stay Informed with Top Election News",
      metaDescription:
        "Stay updated on the latest election news, schedules, exit polls, and results for the upcoming Assembly Elections on TheLallantop  in text format. Get comprehensive coverage and insights into India's democratic process.",
    },
    entertainment: {
      metaTitle:
        "Entertainment Articles: Explore the World of Entertainment News",
      metaDescription:
        "Get the latest entertainment news in Hindi, including Bollywood news, celebrity gossip, and more on TheLallantop in text format. Stay updated with the latest happenings in the world of entertainment.",
    },
    factcheck: {
      metaTitle: "Fact Check - Verify the Truth | Text - TheLallantop",
      metaDescription:
        "Get accurate and reliable fact checks on a wide range of topics in text format with our Fact Check section on TheLallantop. Stay informed and verify the truth.",
    },
    lallankhas: {
      metaTitle: "Lallankhas | Hindi Satire & Humor | Text - TheLallantop",
      metaDescription:
        "Explore the lighter side of news & current affairs with Lallankhas in text format, a section dedicated to Hindi satire and humor. Find witty commentary and satirical takes on trending topics on TheLallantop.",
    },
    news: {
      metaTitle: "Latest News and Updates - The Lallantop",
      metaDescription:
        "Stay informed with the latest news and updates from around the world in text format. Get breaking news, trending stories, and insightful analysis on politics, entertainment, technology, and more.",
    },
    oddnaari: {
      metaTitle:
        "Oddnaari - Stories of Empowered Women | Text  - The Lallantop",
      metaDescription:
        "Explore Oddnaari on The Lallantop and discover inspiring stories in text format of empowered women breaking barriers, challenging norms, and making a difference in the world.",
    },
    sports: {
      metaTitle:
        "Latest Sports News (स्पोर्ट्स न्यूज़) in Hindi, Sports न्यूज़ Headlines, खेल समाचार, Latest Sports News Today",
      metaDescription:
        "Latest Sports News in Hindi: Find latest Sports News in Hindi (स्पोर्ट्स न्यूज़), खेल समाचार, क्रिकेट समाचार, live scores, news on Football (फुटबॉल), Badminton (बॅडमिंटन), Cricket (क्रिकेट), Hockey (हॉकी) in Hindi at The Lallantop  ",
    },
    technology: {
      metaTitle: "Technology Articles: Stay Updated with Tech Trends",
      metaDescription:
        "Stay updated with the latest technology news & updates in text format on TheLallantop. Get insights into the world of technology, gadgets, and innovations with our comprehensive coverage.",
    },
    trending: {
      metaDescription:
        "Trending news in hindi on Thelallantop in audio format. Find breaking hindi news and articles headlines. Trending hindi news, photos, video & more",
    },
  },
  video: {
    "lok-sabha-chunav-2024": {
      metaTitle:
        " Lok Sabha Elections (लोकसभा चुनाव) 2024 Videos: Lok Sabha Chunav 2024 Video and News Updates",
      metaDescription:
        "Lok Sabha Chunav 2024: Stay updated with the latest videos covering Lok Sabha Election 2024, including schedules, exit polls, results, and opinion polls",
      metaKeyword:
        "Election 2024 Videos, Lok Sabha Election 2024 Videos, General Election 2024 Videos, Lok Sabha Election 2024 News Videos, Lok Sabha Chunav Videos, General Election 2024 Video Clips, लोकसभा चुनाव 2024 वीडियो, लोकसभा चुनाव समाचार वीडियो",
    },
    latest: {
      metaTitle:
        "Latest Videos, Trending News Videos Clips, Watch Videos Online on The Lallantop",
      metaDescription:
        "Watch latest videos online on The Lallantop videos section covering latest news videos. Stay informed with our high-quality video content on business, entertainment, celebrities videos, etc.",
      metaKeyword:
        "Latest Videos, News Videos Clips, Watch Videos Online, breaking news videos, politics news videos, celebrities videos, top video news, trending videos",
    },
    trending: {
      metaDescription:
        "Top video news in hindi on Thelallantop. Find breaking hindi news and articles headlines. Trending hindi news, photos, video & more",
    },
    business: {
      metaTitle:
        "Business News in Hindi - Latest Business Updates Videos | TheLallantop",
      metaDescription:
        "Stay updated with the latest business news and updates in video format on TheLallantop. Get insights into the world of business and finance with our comprehensive coverage.",
    },
    education: {
      metaTitle:
        "Education Videos: Informative Insights and Learning Resources",

      metaDescription:
        "Find the latest education news in India in Hindi on TheLallantop. Stay updated with the current affairs, exam updates, and educational news in video format.",
    },
    election: {
      metaTitle:
        "Election Videos: In-Depth Coverage and Analysis on Election News",

      metaDescription:
        "Stay updated on the latest election news, schedules, exit polls, and results for the upcoming Assembly Elections on TheLallantop in video format. Get comprehensive coverage and insights into India's democratic process.",
    },
    entertainment: {
      metaTitle: "Entertainment Videos: Latest Trends, Reviews, and More",

      metaDescription:
        "Get the latest entertainment news in Hindi, including Bollywood news, celebrity gossip, and more on TheLallantop  in video format. Stay updated with the latest happenings in the world of entertainment.",
    },
    factcheck: {
      metaTitle: "Fact Check - Verify the Truth | Video -TheLallantop",

      metaDescription:
        "Get accurate and reliable fact checks on a wide range of topics in video format with our Fact Check section on TheLallantop. Stay informed and verify the truth.",
    },
    lallankhas: {
      metaTitle: "Lallankhas | Hindi Satire & Humor | Video - TheLallantop",

      metaDescription:
        "Explore the lighter side of news and current affairs with Lallankhas in video format, a section dedicated to Hindi satire and humor. Find witty commentary and satirical takes on trending topics on TheLallantop.",
    },
    news: {
      metaTitle: "Latest News Videos and Updates - The Lallantop",

      metaDescription:
        "Stay informed with the latest news and updates from around the world in video format. Get breaking news, trending stories, and insightful analysis on politics, entertainment, technology, and more.",
    },
    oddnaari: {
      metaTitle:
        "Oddnaari - Stories of Empowered Women | Videos - The Lallantop",

      metaDescription:
        "Explore Oddnaari on The Lallantop and discover inspiring stories in video format of empowered women breaking barriers, challenging norms, and making a difference in the world.",
    },
    sports: {
      metaTitle:
        "Latest Sports News (स्पोर्ट्स न्यूज़) in Hindi, Sports न्यूज़ Headlines, खेल समाचार, Latest Sports News Today",

      metaDescription:
        "Latest Sports News in Hindi: Find latest Sports News in Hindi (स्पोर्ट्स न्यूज़), खेल समाचार, क्रिकेट समाचार, live scores, news on Football (फुटबॉल), Badminton (बॅडमिंटन), Cricket (क्रिकेट), Hockey (हॉकी) in Hindi at The Lallantop  ",
    },
    technology: {
      metaTitle: "Technology Videos: Stay Up-to-Date with Tech Innovations",

      metaDescription:
        "Stay updated with the latest technology news and updates in video format on TheLallantop. Get insights into the world of technology, gadgets, and innovations with our comprehensive coverage.",
    },
    trending: {
      metaTitle:
        "Trending news Videos in Hindi, breaking news and headlines in Hindi - TheLallantop",

      metaDescription:
        "Trending news in hindi on Thelallantop in video format. Find breaking hindi news and articles headlines. Trending hindi news, photos, video & more",
    },
  },
  show: {
    thelallantopshow: {
      metaTitle: "The Lallantop Show: Uncovering Stories That Matter",
    },
    duniyadari: {
      metaTitle: "Duniyadari: A Comprehensive Exploration of Global Affairs",
    },
    guestinthenewsroom: {
      metaTitle:
        "Guest in the Newsroom: Insights from Influential Personalities",
    },
    netanagri: {
      metaTitle:
        "Netanagri: In-Depth Analysis of Politics and Governance on The Lallantop",
    },
    lallantopbaithki: {
      metaTitle: "Lallantop Baithak: Thought-Provoking Discussions and Debates",
    },
    kharchapaani: {
      metaTitle:
        "Kharcha Paani: Your Guide to Financial Planning and Money Matters",
    },
    sociallist: {
      metaTitle:
        "Social List: Exploring Trends, Viral Stories, and Online Culture",
    },
    tarikh: {
      metaTitle: "Tarikh: A Journey Through Historical Narratives and Stories",
    },
    thecinemashow: {
      metaTitle:
        "The Cinema Show: Your Gateway to the World of Movies and Film",
    },
    sehat: {
      metaTitle: "Sehat: Your Source for Health and Wellness Insights",
    },
    kitabwala: {
      metaTitle:
        "Kitabwala: A Journey Through the World of Literature and Books",
    },
    jamghat: {
      metaTitle:
        "Experience Jamghat: A Journey into Inspiring Social Initiatives",
    },
    surkhiyan: {
      metaTitle: "Surkhiyan: Stay Updated with the Latest News and Headlines",
    },
    zananarepublic: {
      metaTitle:
        "Zanana Republic: Empowering Women with Inspiring Stories and Discussions",
    },
    kitabibaatein: {
      metaTitle:
        "Kitabi Baatein: Conversations About Books and Literary Treasures",
    },
  },
};
